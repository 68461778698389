import { Settings } from "@mui/icons-material";
import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";

/****Layouts*****/
const Login = lazy(() => import("../layouts/Login.jsx"));
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Dashboard = lazy(() => import("../views/Dashboard.js"));
const Blog = lazy(() => import("../views/Blog.jsx"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Projects = lazy(() => import("../views/ui/Projects.jsx"));  
const Register = lazy(() => import("../views/ui/Register.jsx"));
const Teams = lazy(() => import("../views/ui/Team.jsx"));
const Career = lazy(() => import("../views/ui/Career.jsx"));
const Tools = lazy(() => import("../views/ui/Tools.jsx"));
const Newsletter = lazy(() => import("../views/ui/Newsletter.jsx"));
const Setting = lazy(() => import("../views/ui/Setting.jsx"));
const Services = lazy(() => import("../views/ui/Services.jsx"));
const Products = lazy(() => import("../views/ui/Products.jsx"));
const Reviews = lazy(() => import("../views/ui/Reviews.jsx"));
const Quries = lazy(() => import("../views/ui/Queries.jsx"))


const ThemeRoutes = [ 
  {  path: "/",
    element: <Login />, 
  },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/projects", exact: true, element: <Projects /> },
      { path: "/blog", exact: true, element: <Blog /> },
      { path: "/register", exact: true, element: <Register /> },
      { path: "/notifications", exact: true, element: <Tools /> },
      { path: "/team", exact: true, element: <Teams /> },
      { path: "/career", exact: true, element: <Career /> },
      { path: "/tool", exact: true, element: <Tools /> },
      { path: "/newsletter",exact: true, element: <Newsletter/>},
      { path: "/setting", exact: true, element: <Setting /> },
      { path: "/reviews", exact: true, element: <Reviews /> },
      { path: "/query", exact: true, element: <Quries /> },
      { path: "/services", exact: true, element: <Services /> },
      { path: "/products", exact: true, element: <Products /> },
      { path: "*", element: <Navigate to="/dashboard" replace /> }, 
    ],
  },
];

export default ThemeRoutes;



