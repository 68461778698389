import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';


let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    console.log("printing base url:0", baseUrl)
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

// Create a context
export const ProjectsContext = createContext();

// Create a provider component
export const ProjectsProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch projects
    const fetchProjects = async () => {
        try {
            const response = await axios.get(`${baseUrl}/projects`);
            setProjects(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/categories`);
            console.log('ct response', response)
            setCategories(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    // Fetch projects on component mount
    useEffect(() => {
        fetchProjects();
        fetchCategories();
    }, []);

    return (<ProjectsContext.Provider value={{ projects, categories, loading, error, fetchProjects, fetchCategories }}>
        {children}
    </ProjectsContext.Provider>

    );
};
