import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Base Url
let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

export const CareersContext = createContext();

export const CareersProvider = ({ children }) => {
    const [careers, setCareers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCareers = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/careers`);
            setCareers(response.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/careers-categories`);
            setCategories(response.data);
             setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCareers();
        fetchCategories();
    }, []);

    const addCareer = async (career) => {
        try {
            const response = await axios.post(`${baseUrl}/careers`, career, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setCareers([...careers, response.data]);
        } catch (error) {
            setError(error);
        }
    };

    const updateCareer = async (id, updatedCareer) => {
        try {
            const response = await axios.put(`${baseUrl}/careers/${id}`, updatedCareer, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setCareers(
                careers.map((career) => (career.id === id ? response.data : career))
            );
        } catch (error) {
            setError(error);
        }
    };

    const deleteCareer = async (id) => {
        try {
            await axios.delete(`${baseUrl}/careers/${id}`);
            setCareers(careers.filter((career) => career.id !== id));
        } catch (error) {
            setError(error);
        }
    };

    return (
        <CareersContext.Provider
            value={{
                careers,
                categories,
                loading,
                error,
                addCareer,
                updateCareer,
                deleteCareer,
                fetchCareers,
                fetchCategories
            }}
        >
            {children}
        </CareersContext.Provider>
    );
};
