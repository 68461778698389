import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    console.log("printing base url:", baseUrl);
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

// Create a context
export const ServicesContext = createContext();

// Create a provider component
export const ServicesProvider = ({ children }) => {
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch services
    const fetchServices = async () => {
        try {
            const response = await axios.get(`${baseUrl}/services`);
            setServices(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/svc-categories`);
            console.log('ct response', response);
            setCategories(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch services and categories on component mount
    useEffect(() => {
        fetchServices();
        fetchCategories();
    }, []);

    return (
        <ServicesContext.Provider value={{ services, categories, loading, error, fetchServices, fetchCategories }}>
            {children}
        </ServicesContext.Provider>
    );
};
