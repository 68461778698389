import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    console.log("printing base url:", baseUrl);
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

// Create a context
export const ProductsContext = createContext();

// Create a provider component
export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch products
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${baseUrl}/products`);
            setProducts(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/prod-categories`);
            console.log('ct response', response);
            setCategories(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch products and categories on component mount
    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);

    return (
        <ProductsContext.Provider value={{ products, categories, loading, error, fetchProducts, fetchCategories }}>
            {children}
        </ProductsContext.Provider>
    );
};
