import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const ReviewsContext = createContext();

const ReviewsProvider = ({ children }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASE_URL : process.env.REACT_APP_PROD_BASE_URL;

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get(`${baseUrl}/reviews`);
                setReviews(response.data);
                console.log("reviws", response.data)
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchReviews();
    }, [baseUrl]);

    const addReview = async (reviewData) => {
        try {
            const response = await axios.post(`${baseUrl}/reviews`, reviewData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setReviews([...reviews, response.data]);
        } catch (err) {
            setError(err);
        }
    };

    const updateReview = async (id, reviewData) => {
        try {
            const response = await axios.put(`${baseUrl}/reviews/${id}`, reviewData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setReviews(reviews.map((review) => (review._id === id ? response.data : review)));
        } catch (err) {
            setError(err);
        }
    };

    const deleteReview = async (id) => {
        try {
            await axios.delete(`${baseUrl}/reviews/${id}`);
            setReviews(reviews.filter((review) => review._id !== id));
        } catch (err) {
            setError(err);
        }
    };

    return (
        <ReviewsContext.Provider value={{ reviews, loading, error, addReview, updateReview, deleteReview }}>
            {children}
        </ReviewsContext.Provider>
    );
};

export default ReviewsProvider;
