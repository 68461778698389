import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

// Create a context
export const BlogContext = createContext();

// Create a provider component
export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch blogs
    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${baseUrl}/blogs`);
            setBlogs(response.data);
            // console.log("blogs=====>", response.data)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch categories
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/blog-categories`);
            setCategories(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to add a new blog
    const addBlog = async (newBlog) => {
        try {
            const response = await axios.post(`${baseUrl}/blogs`, newBlog, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setBlogs([...blogs, response.data]);
        } catch (error) {
            console.error('Error adding blog:', error);
            throw new Error('Failed to add blog');
        }
    };

    // Function to update an existing blog
    const updateBlog = async (updatedBlog) => {
        try {
            const response = await axios.put(`${baseUrl}/blogs/${updatedBlog._id}`, updatedBlog, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // console.log("updated blog===", response.data)
            setBlogs(blogs.map(blog => blog._id === updatedBlog._id ? updatedBlog : blog));
        } catch (error) {
            console.error('Error updating blog:', error);
            throw new Error('Failed to update blog');
        }
    };

    // Function to delete a blog
    const deleteBlog = async (blogId) => {
        try {
            await axios.delete(`${baseUrl}/blogs/${blogId}`);
            setBlogs(blogs.filter(blog => blog._id !== blogId));
        } catch (error) {
            console.error('Error deleting blog:', error);
            throw new Error('Failed to delete blog');
        }
    };

    // Fetch blogs and categories on component mount
    useEffect(() => {
        fetchBlogs();
        fetchCategories();
    }, []);

    return (
        <BlogContext.Provider value={{ blogs, categories, loading, error, deleteBlog, updateBlog, fetchBlogs, fetchCategories }}>
            {children}
        </BlogContext.Provider>
    );
};
