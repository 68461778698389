import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

// Create a context
export const ToolsContext = createContext();

// Create a provider component
export const ToolsProvider = ({ children }) => {
    const [tools, setTools] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch tools
    const fetchTools = async () => {
        try {
            const response = await axios.get(`${baseUrl}/tools`);
            setTools(response.data);
            console.log("tools=====>", response.data)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch categories
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/tools-categories`);
            setCategories(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to add a new tools
    const addTools = async (newTools) => {
        try {
            const response = await axios.post(`${baseUrl}/tools`, newTools, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setTools([...tools, response.data]);
        } catch (error) {
            console.error('Error adding tools:', error);
            throw new Error('Failed to add tools');
        }
    };

    // Function to update an existing tools
    const updateTools = async (updatedTools) => {
        try {
            const response = await axios.put(`${baseUrl}/tools/${updatedTools._id}`, updatedTools, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // console.log("updated tools===", response.data)
            setTools(tools.map(tools => tools._id === updatedTools._id ? updatedTools : tools));
        } catch (error) {
            console.error('Error updating tools:', error);
            throw new Error('Failed to update tools');
        }
    };

    // Function to delete a tools
    const deleteTools = async (toolsId) => {
        try {
            await axios.delete(`${baseUrl}/tools/${toolsId}`);
            setTools(tools.filter(tools => tools._id !== toolsId));
        } catch (error) {
            console.error('Error deleting tools:', error);
            throw new Error('Failed to delete tools');
        }
    };

    // Fetch tools and categories on component mount
    useEffect(() => {
        fetchTools();
        fetchCategories();
    }, []);

    return (
        <ToolsContext.Provider value={{ tools, categories, loading, error, deleteTools, updateTools, fetchTools, fetchCategories }}>
            {children}
        </ToolsContext.Provider>
    );
};
