import React, { Suspense } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import { ProjectsProvider } from "./Context/projectContext";
import { ServicesProvider } from "./Context/serviceContext";
import { ProductsProvider } from "./Context/productContext";
import { BlogProvider } from "./Context/blogContext";
import { TeamProvider } from "./Context/teamContext";
import { CareersProvider } from "./Context/careerContext";
import ReviewsProvider from "./Context/reviewContext"; 
import {ToolsProvider} from "./Context/toolsContext";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Suspense fallback={<Loader />}>
    <ToolsProvider>
    <ReviewsProvider>
      <CareersProvider>
        <TeamProvider>
          <BlogProvider>
            <ProductsProvider>
              <ServicesProvider>
                <ProjectsProvider>
                  <HashRouter>
                    <App />
                  </HashRouter>
                </ProjectsProvider>
              </ServicesProvider>
            </ProductsProvider>
          </BlogProvider>
        </TeamProvider>
      </CareersProvider>
    </ReviewsProvider>
  </ToolsProvider>
</Suspense>

  // document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
