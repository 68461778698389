import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

let baseUrl;
if (process.env.NODE_ENV === 'development') {
    baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    console.log("printing base url:", baseUrl);
} else {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
}

// Create a context
export const TeamContext = createContext();

// Create a provider component
export const TeamProvider = ({ children }) => {
    const [team, setTeam] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch team members
    const fetchTeam = async () => {
        try {
            const response = await axios.get(`${baseUrl}/team`);
            setTeam(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch team categories
    const fetchTeamCategories = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/team-categories`);
            setRoles(response.data);
            console.log("teams  ===> ", response.data)

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch team members and team categories on component mount
    useEffect(() => {
        fetchTeam();
        fetchTeamCategories();
    }, []);

    return (
        <TeamContext.Provider value={{ team, roles, loading, error, fetchTeam, fetchTeamCategories }}>
            {children}
        </TeamContext.Provider>
    );
};
